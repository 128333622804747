import React, { useState } from 'react';
import { string } from 'prop-types';
import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Input,
  Select,
  Text,
  useToast,
  Heading,
  useDisclosure,
  Flex,
} from '@chakra-ui/react';

import WithAnimation from '@components/Common/WithAnimation';
import GuestInformation from './GuestInformation';

import useMutation from '@/hooks/useMutation';
import useGuestInfo from '@/hooks/useGuestInfo';
import usePartner from '@hooks/usePartner';
import useFamily from '@hooks/useFamily';

import { API_HOSTNAME, API_RSVP_TABLE } from '@/constants';
import { BUTTON_GRADIENT, BUTTON_PROPS, NAVIGATION_COLOR } from '@/constants/colors';
import { BG_RSVP } from '@/constants/assets';

import {
  FORM_LABEL_PROPS,
  INPUT_COMMON_PROPS,
  SELECT_COMMON_PROPS,
  TYPE,
  ERROR_TYPE,
  ERROR_PROPS,
} from './types';
import {
  ENABLE_GUEST_INFORMATION,
  ENABLE_PARTNER_MODE,
  ENABLE_RSVP_ADDRESS,
  ENABLE_RSVP_PHONE_NUMBER,
} from '@/constants/feature-flags';

import { txtForm as txt } from './locales';
import { Fragment } from 'react';

const enc = (string) => encodeURIComponent(string);
const defaultAddress = ENABLE_RSVP_ADDRESS ? '' : '-';
const defaultPhoneNumber = ENABLE_RSVP_PHONE_NUMBER ? '' : '-';

/**
 * function to render RSVP component
 * @returns {JSX.Element}
 * @author idindrakusuma
 */
function RSVPSection({ lang, ...rest }) {
  const toast = useToast();
  const { name: guestName = '' } = useGuestInfo();
  const finalName = guestName.toLowerCase() === 'guest' ? '' : guestName;
  const { isOpen, onClose, onOpen } = useDisclosure();

  const [onSentConfirmation, loading] = useMutation(API_HOSTNAME, API_RSVP_TABLE, 'insert');
  const partner = usePartner();
  const isFamily = useFamily();

  const [name, setName] = useState(() => finalName);
  const [phone, setPhone] = useState(defaultPhoneNumber);
  const [address, setAddress] = useState(defaultAddress);
  const [attended, setAttended] = useState(TYPE.YES);
  const [bringPartner, setBringPartner] = useState(1);
  const [errorType, setErrorType] = useState(ERROR_TYPE);
  const bringPartnerInt = parseInt(bringPartner, 0);

  /**
   * function to set state
   * @param {object} e - html event value
   * @param {function} setState
   * @param {string} typeState
   * @returns
   */
  const onSetState = (e, setState, typeState) => {
    const value = e.target.value;
    setState(value);

    if (typeState === 'email') {
      if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(value)) {
        setErrorType({ ...errorType, email: 'Invalid email address' });
        return;
      }
    }

    if (typeState === 'phone') {
      if (!/\d+/.test(value) || value.length > 13) {
        setErrorType({ ...errorType, phone: 'Invalid phone number' });
        return;
      }
    }

    setErrorType(ERROR_TYPE);
  };

  /**
   * function to submit to BE with check the form value first
   */
  const onSubmitForm = async () => {
    if (errorType.name || errorType.phone || errorType.address) return;

    if (!name || !phone || !address) {
      setErrorType({
        name: !name ? txt.required[lang] : '',
        phone: !phone ? txt.required[lang] : '',
        address: !address ? txt.required[lang] : '',
      });

      return;
    }

    const query =
      `nama=${enc(name)}` +
      `&hp=${phone}` +
      `&hadir=${enc(attended)}` +
      `&jumlah_tamu=${attended === TYPE.NO ? '' : bringPartner}` +
      `&alamat=${enc(address)}`;

    const result = await onSentConfirmation(query);

    if (result.success) {
      toast({
        title: txt.success[lang],
        description: txt.msgSuccess[lang],
        status: 'success',
        duration: 5000,
        isClosable: true,
      });

      setName('');
      setPhone(defaultPhoneNumber);
      setAddress(defaultAddress);
    } else {
      toast({
        title: 'Oops!',
        description: txt.msgError[lang],
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <Box
      bgColor="bgPrimary"
      bgImage={`url(${BG_RSVP})`}
      bgSize="cover"
      bgPosition="center"
      position="relative"
      {...rest}
    >
      <Box
        height="100%"
        bg="transparent"
        bgImage="linear-gradient(180deg, #eee3d700 0%, #e5e3cfd9 100%)"
        transition="background 0.3s, border-radius 0.3s, opacity 0.3s"
        opacity="1"
        padding="62px 32px 42px 32px"
      >
        <Box marginTop="42px" width="100%" zIndex={2} position="relative">
          <Box
            padding="24px 16px"
            borderRadius="16px"
            border="1px solid"
            borderColor={NAVIGATION_COLOR}
            bgColor="rgb(229 227 207 / 61%)"
            position="relative"
            boxShadow="xl"
          >
            <Box padding="24px 16px" width="100%">
              {/* Title & Desc Section */}
              <Box textAlign="center">
                <WithAnimation>
                  <Heading
                    fontWeight="normal"
                    color="mainColorText"
                    size="xl"
                    fontFamily="CustomFont"
                    textTransform="uppercase"
                  >
                    {txt.title[lang]}
                  </Heading>
                </WithAnimation>
                <WithAnimation>
                  <Text
                    color="mainColorText"
                    fontSize="md"
                    margin="16px 0 24px 0"
                    padding="0 12px 0 12px"
                    dangerouslySetInnerHTML={{ __html: txt.desc[lang] }}
                  />
                </WithAnimation>
              </Box>

              {/* Form Sections - Name */}
              <WithAnimation>
                <FormControl margin="8px 0" isInvalid={errorType.name}>
                  <FormLabel {...FORM_LABEL_PROPS}>{txt.name[lang]}:</FormLabel>
                  <Input
                    {...INPUT_COMMON_PROPS}
                    placeholder="..."
                    value={name}
                    onChange={(e) => onSetState(e, setName)}
                  />
                  <FormErrorMessage {...ERROR_PROPS}>{`* ${errorType.name}`}</FormErrorMessage>
                </FormControl>
              </WithAnimation>
              {/* Form Sections - Phone Number */}
              {ENABLE_RSVP_PHONE_NUMBER && (
                <WithAnimation>
                  <FormControl margin="16px 0" isInvalid={errorType.phone}>
                    <FormLabel {...FORM_LABEL_PROPS}>{txt.hp[lang]}:</FormLabel>
                    <Input
                      {...INPUT_COMMON_PROPS}
                      placeholder="..."
                      value={phone}
                      onChange={(e) => onSetState(e, setPhone, 'phone')}
                    />
                    <FormErrorMessage {...ERROR_PROPS}>{'* ' + errorType.phone}</FormErrorMessage>
                  </FormControl>
                </WithAnimation>
              )}
              {/* Form Sections - Address */}
              {ENABLE_RSVP_ADDRESS && (
                <WithAnimation>
                  <FormControl margin="8px 0" isInvalid={errorType.address}>
                    <FormLabel {...FORM_LABEL_PROPS}>{txt.address[lang]}:</FormLabel>
                    <Input
                      {...INPUT_COMMON_PROPS}
                      height="40px"
                      placeholder="..."
                      value={address}
                      onChange={(e) => onSetState(e, setAddress)}
                    />
                    <FormErrorMessage {...ERROR_PROPS}>{'* ' + errorType.address}</FormErrorMessage>
                  </FormControl>
                </WithAnimation>
              )}
              {/* Form Sections - Attendance */}
              <WithAnimation>
                <FormControl margin="8px 0">
                  <FormLabel {...FORM_LABEL_PROPS}>{txt.willYoutAttend[lang]}</FormLabel>
                  <Select
                    {...SELECT_COMMON_PROPS}
                    value={attended}
                    onChange={(e) => onSetState(e, setAttended)}
                  >
                    <option value={TYPE.YES} color="white">
                      {txt.willAttend[lang]}
                    </option>
                    <option value={TYPE.NO}>{txt.noAttend[lang]}</option>
                  </Select>
                </FormControl>
              </WithAnimation>
              {/* Form Sections - Partner */}
              {attended === TYPE.YES && (
                <WithAnimation>
                  <FormControl>
                    <FormLabel {...FORM_LABEL_PROPS}>{txt.willYouBringPartner[lang]}</FormLabel>
                    <Select
                      {...SELECT_COMMON_PROPS}
                      value={bringPartner}
                      onChange={(e) => onSetState(e, setBringPartner)}
                    >
                      {ENABLE_PARTNER_MODE ? (
                        <Fragment>
                          {(partner > 0 || partner === 0) && (
                            <option value={1} style={{ color: 'black' }}>
                              1
                            </option>
                          )}
                          {partner > 1 && (
                            <option value={2} style={{ color: 'black' }}>
                              2
                            </option>
                          )}
                          {partner > 2 && (
                            <option value={3} style={{ color: 'black' }}>
                              3
                            </option>
                          )}
                          {partner > 3 && (
                            <option value={4} style={{ color: 'black' }}>
                              4
                            </option>
                          )}
                          {partner > 4 && (
                            <option value={5} style={{ color: 'black' }}>
                              5
                            </option>
                          )}
                        </Fragment>
                      ) : (
                        <Fragment>
                          <option value={1} style={{ color: 'black' }}>
                            1
                          </option>
                          <option value={2} style={{ color: 'black' }}>
                            2
                          </option>
                        </Fragment>
                      )}
                    </Select>
                    {/* SET to TRUE if you want to add some information if user bring-partner YES */}
                    {bringPartnerInt > TYPE.YES && false && (
                      <FormHelperText color="mainColorText" fontSize="sm" fontStyle="italic">
                        {txt.singleGuestInfo[lang]}
                      </FormHelperText>
                    )}
                  </FormControl>
                </WithAnimation>
              )}

              <WithAnimation>
                <Flex justifyContent="end">
                  {ENABLE_GUEST_INFORMATION && (
                    <Button
                      bgColor="bgPrimary"
                      marginTop="24px"
                      size="sm"
                      type="button"
                      fontFamily="body"
                      fontWeight="normal"
                      fontSize="xs"
                      color="mainColorText"
                      onClick={onOpen}
                      borderRadius="8px"
                      marginRight="8px"
                      {...BUTTON_PROPS}
                    >
                      {isFamily ? 'FAMILY INFORMATION' : 'GUEST INFORMATION'}
                    </Button>
                  )}
                  <Button
                    bgColor="bgAlternative"
                    isLoading={loading}
                    size="sm"
                    type="button"
                    fontFamily="body"
                    fontWeight="normal"
                    boxShadow="md"
                    color="mainColorText"
                    letterSpacing="2px"
                    padding="4px 16px"
                    borderRadius="16px"
                    textTransform="uppercase"
                    display="block"
                    margin="24px auto 0 auto"
                    onClick={onSubmitForm}
                    {...BUTTON_PROPS}
                    background={BUTTON_GRADIENT}
                  >
                    {txt.submit[lang]}
                  </Button>
                </Flex>
              </WithAnimation>
            </Box>
          </Box>
        </Box>
      </Box>
      <GuestInformation visible={isOpen} onClose={onClose} lang={lang} />
    </Box>
  );
}

RSVPSection.propTypes = {
  lang: string,
};

RSVPSection.defaultProps = {
  lang: 'id',
};

export default React.memo(RSVPSection);
